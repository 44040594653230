import React, { useContext } from "react";
import { GlobalContex } from "../../globalContext";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import allBrands from "../../Assets/images/icons/allBrands.svg";

const CategorySection = () => {
  const {
    setSelectedCategory,
    selectedCategory,
    loading,
    allcategories,
    catLoading,
    collapse,
  } = useContext(GlobalContex);
  const navigate = useNavigate();
  return (
    <>
      <div className="categorySection">
        <div
          onClick={(e) => {
            setSelectedCategory("all brands");
            navigate("/brands");
          }}
          className={
            selectedCategory === "all brands" || !selectedCategory
              ? "selectedCategoryStyle"
              : "categoryStyle"
          }
        >
          <img
            src={allBrands}
            alt=""
            style={{ height: "30px", width: "30px" }}
          />
          {collapse ? (
            ""
          ) : (
            <div style={{ fontSize: "14px", fontWeight: 600, padding: "" }}>
              All Brands
            </div>
          )}
        </div>
        {!catLoading
          ? allcategories?.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={(e) => {
                    setSelectedCategory(item);
                    navigate(
                      `/brands/${item.name.replace(/\s+/g, "-").toLowerCase()}`
                    );
                  }}
                  className={
                    item.category_id === selectedCategory?.category_id
                      ? "selectedCategoryStyle"
                      : "categoryStyle"
                  }
                >
                  <img
                    src={item.icon}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                  {collapse ? (
                    ""
                  ) : (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              );
            })
          : Array(10)
              .fill("")
              .map((item) => {
                return (
                  <div className={"categoryStyle"}>
                    <Skeleton
                      className="dp"
                      circle
                      width={30}
                      height={30}
                      // style={{ marginRight: "20px" }}
                    />

                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "",
                      }}
                    >
                      <Skeleton width={120} style={{ height: "15px" }} />
                    </div>
                  </div>
                );
              })}
      </div>
    </>
  );
};

export default CategorySection;
