import logo from "./logo.svg";
import "./App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import SplashHeader from "./components/SplashHeader";
import { GlobalContex } from "./globalContext";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useContext } from "react";

import brain from "./Assets/images/brain.svg";
import Brands from "./pages/Brands";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

function App() {
  const {
    showDraw,
    setShowDraw,
    setAllcategories,
    setAllApps,
    selectedCategory,
    getAllCategories,
    getAllApps,
    getSelectedCategoryApps,
    showDropdown,
    setShowDropdown,
  } = useContext(GlobalContex);

  const [loading, setLoading] = useState(false);

  return (
    <div className="App">
      <div
        onMouseDown={(e) => setShowDraw(false)}
        // style={{ overflow: disableBack ? "none" : "" }}
        className={showDraw ? "overlayClose" : ""}
      ></div>

      {/* Put Routes here */}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={`/brands`} />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brands/:id" element={<Brands />}>
            {/* <Route path="*" element={<CategoryBrands />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
