import React, { useContext, useEffect, useState } from "react";
import { GlobalContex } from "../../globalContext";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../../Assets/images/icons/app_placeholder.png";
import search from "../../Assets/images/icons/search.svg";
import loadingGif from "../../Assets/images/loading.gif";

const AppGridSection = () => {
  const { allApps, appLoading, allcategories, searchInput } =
    useContext(GlobalContex);
  const [lazyLoadImg, setLazyLoadImg] = useState(false);
  const [filteredApps, setFilteredApps] = useState([]);

  useEffect(() => {
    if (allApps.length > 0) {
      setTimeout(() => {
        setLazyLoadImg(true);
      }, 1000);
    }
  }, [allApps]);

  useEffect(() => {
    if (allApps.length > 0) {
      setFilteredApps(
        allApps?.filter((item) =>
          item?.app_name?.toLowerCase()?.includes(searchInput?.toLowerCase())
        )
      );
    }
  }, [allApps, searchInput]);

  return (
    <>
      <div className="appGridSection">
        {!appLoading
          ? filteredApps?.map((item, index) => {
              return (
                <div className="individualApps" key={index}>
                  <div className="appIcon">
                    <img
                      // className="fakeImage"
                      src={
                        lazyLoadImg
                          ? item.app_icon
                            ? item.app_icon
                            : defaultImg
                          : loadingGif
                      }
                      alt="app"
                      style={{ height: "40px", width: "40px" }}
                    />
                  </div>

                  <div style={{ textAlign: "left", paddingLeft: "23px" }}>
                    <div className="title">{item?.app_name}</div>
                    <div className="subtitle">
                      {item?.short_description
                        ? item?.short_description.length > 30
                          ? item?.short_description.substring(0, 30) + "..."
                          : item?.short_description
                        : ""}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "15px",
                      }}
                    >
                      <div className="actionButtons">Learn</div>
                      <div
                        className="actionButtons"
                        onClick={(e) =>
                          window.open(`http://${item.website}`, "_blank")
                        }
                      >
                        Website
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : Array(20)
              .fill("")
              .map((item, index) => {
                return (
                  <div className="individualApps" key={index}>
                    <div className="appIcon">
                      <img
                        // className="fakeImage"
                        src={loadingGif}
                        alt="app"
                        style={{ height: "40px", width: "40px" }}
                      />
                      {/* <Skeleton
                        className="dp"
                        circle
                        width={40}
                        height={40}
                        // style={{ marginRight: "20px" }}
                      /> */}
                    </div>
                    <div style={{ textAlign: "left", paddingLeft: "23px" }}>
                      <Skeleton width={150} style={{ height: "12px" }} />

                      <div className="subtitle">
                        <Skeleton width={120} style={{ height: "10px" }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "15px",
                        }}
                      >
                        <div className="actionButtons">&nbsp;</div>
                        <div className="actionButtons">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                );
              })}
      </div>
      {/* Mobile View of App Grid */}
      <div className="mobileAppGridSection">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <div className="categoryTitle">All Brands</div>
              <div className="categorySubTitle">
                Check Out The Latest Updates & Apps
              </div>
            </div>
            {/* <img
              src={search}
              alt=""
              style={{ width: "15px", height: "15px" }}
            /> */}
          </div>
          <div
            className="horizonalScrollDiv"
            style={{
              display: "flex",
              overflowX: "scroll",
              width: window.innerWidth - 60,
              paddingTop: "28px",
            }}
          >
            {!appLoading
              ? allApps?.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="mobileAppIcon">
                        <img
                          src={item.app_icon ? item.app_icon : defaultImg}
                          alt=""
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <div className="appTitle">{item?.app_name}</div>
                        <div className="appSubTitle">
                          {item?.short_description.split(" ")[2]
                            ? item?.short_description.split(" ")[0] +
                              " " +
                              item?.short_description.split(" ")[1] +
                              " " +
                              item?.short_description.split(" ")[2]
                            : item?.short_description}
                        </div>
                      </div>
                    </div>
                  );
                })
              : Array(20)
                  .fill("")
                  .map((item, index) => {
                    return (
                      <div>
                        <div className="mobileAppIcon">
                          <Skeleton
                            className="dp"
                            circle
                            width={40}
                            height={40}
                            // style={{ marginRight: "20px" }}
                          />
                        </div>
                        <Skeleton width={150} style={{ height: "12px" }} />
                        <Skeleton width={120} style={{ height: "10px" }} />
                      </div>
                    );
                  })}
          </div>
        </div>

        {allcategories.map((item1) => {
          return (
            <div style={{ paddingTop: "60px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <div className="categoryTitle">{item1.name}</div>
                  <div className="categorySubTitle">
                    Check Out The Latest Updates & Apps
                  </div>
                </div>
                {/* <img
                  src={search}
                  alt=""
                  style={{ width: "15px", height: "15px" }}
                /> */}
              </div>
              <div
                className="horizonalScrollDiv"
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  width: window.innerWidth - 60,
                  paddingTop: "28px",
                }}
              >
                {!appLoading
                  ? allApps?.map((item, index) => {
                      if (
                        allApps[index].categories.find(
                          (o) => o.name === item1.name
                        )
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="mobileAppIcon">
                              <img
                                src={item.app_icon ? item.app_icon : defaultImg}
                                alt=""
                                style={{ height: "40px", width: "40px" }}
                              />
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <div className="appTitle">{item?.app_name}</div>
                              <div className="appSubTitle">
                                {item?.short_description.split(" ")[2]
                                  ? item?.short_description.split(" ")[0] +
                                    " " +
                                    item?.short_description.split(" ")[1] +
                                    " " +
                                    item?.short_description.split(" ")[2]
                                  : item?.short_description}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : Array(20)
                      .fill("")
                      .map((item, index) => {
                        return (
                          <div>
                            <div className="mobileAppIcon">
                              <Skeleton
                                className="dp"
                                circle
                                width={40}
                                height={40}
                                // style={{ marginRight: "20px" }}
                              />
                            </div>
                            <Skeleton width={150} style={{ height: "12px" }} />
                            <Skeleton width={120} style={{ height: "10px" }} />
                          </div>
                        );
                      })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AppGridSection;
