import React, { useState, useContext, useEffect } from "react";
import { GlobalContex } from "../globalContext";

import { useLocation, useNavigate } from "react-router-dom";
import CategorySection from "../components/CategorySection";
import AppGridSection from "../components/AppGridSection";
import SplashHeader from "../components/SplashHeader";
import axios from "axios";

import { ReactComponent as Collapse_img } from "../Assets/images/icons/collapse.svg";
import { ReactComponent as Collapse1_img } from "../Assets/images/icons/collapse1.svg";

const Brands = () => {
  const {
    allcategories,
    setAllcategories,
    setSelectedCategory,
    selectedCategory,
    setAllApps,
    getAllCategories,
    setCatLoading,
    setAppLoading,
    collapse,
    setCollapse,
  } = useContext(GlobalContex);
  const { pathname } = useLocation();

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/gxb/apps/category/get`)
      .then((res) => {
        setAllcategories(res.data.categories);

        var temp = pathname.lastIndexOf("/");
        var result = pathname.substring(temp + 1);

        const foundCategory = res.data.categories.find(
          (o) => o.name.replace(/\s+/g, "-").toLowerCase() === result
        );
        // console.log(result, foundCategory, allcategories, "kwjbedkwjebss");
        if (foundCategory) {
          setAppLoading(true);
          axios
            .get(
              `https://comms.globalxchange.io/gxb/apps/get?category_id=${foundCategory.category_id}`
            )
            .then(({ data }) => {
              if (data.status) {
                console.log(data, "getapps");
                setAllApps(data.apps);
                setAppLoading(false);
              }
            });
        } else {
          setAppLoading(true);
          axios
            .get(`https://comms.globalxchange.io/gxb/apps/get`)
            .then((res) => {
              setAllApps(res.data.apps);
              setAppLoading(false);
            });
        }
        setSelectedCategory(foundCategory);
        setCatLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      selectedCategory !== null &&
      selectedCategory !== undefined &&
      selectedCategory !== "all brands"
    ) {
      setAppLoading(true);
      axios
        .get(
          `https://comms.globalxchange.io/gxb/apps/get?category_id=${selectedCategory.category_id}`
        )
        .then(({ data }) => {
          if (data.status) {
            console.log(data, "getapps");
            setAllApps(data.apps);
            setAppLoading(false);
          }
        });
    } else if (selectedCategory === "all brands") {
      setAppLoading(true);
      axios.get(`https://comms.globalxchange.io/gxb/apps/get`).then((res) => {
        setAllApps(res.data.apps);
        setAppLoading(false);
      });
    }
  }, [selectedCategory]);

  return (
    <>
      <SplashHeader />
      <div className={collapse ? "smallGridContent" : "gridContent"}>
        <CategorySection />
        <AppGridSection />

        <div
          className={collapse ? "collapseButton1" : "collapseButton"}
          onClick={(e) => setCollapse(!collapse)}
        >
          {collapse ? (
            <Collapse1_img fill="#287EFB" stroke="none" />
          ) : (
            <Collapse_img fill="#287EFB" stroke="none" />
          )}
        </div>
      </div>
    </>
  );
};

export default Brands;
