import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const GlobalContex = createContext();

export const GlobalContextProvider = (props) => {
  const [showDraw, setShowDraw] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const [allcategories, setAllcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allApps, setAllApps] = useState([]);

  const [appLoading, setAppLoading] = useState(false);
  const [catLoading, setCatLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  // const getAllCategories = () => {
  //   setCatLoading(true);
  //   axios
  //     .get(`https://comms.globalxchange.io/gxb/apps/category/get`)
  //     .then((res) => {
  //       setAllcategories(res.data.categories);
  //       setCatLoading(false);
  //     });
  // };

  const value = {
    //States

    showDraw,
    setShowDraw,
    selectedMenu,
    setSelectedMenu,
    allcategories,
    setAllcategories,
    selectedCategory,
    setSelectedCategory,
    allApps,
    setAllApps,

    appLoading,
    setAppLoading,
    catLoading,
    setCatLoading,
    collapse,
    setCollapse,
    searchInput,
    setSearchInput,
    //Functions

    // getAllCategories,
    // getAllApps,
    // getSelectedCategoryApps,
  };
  return (
    <GlobalContex.Provider value={value}>
      {props.children}
    </GlobalContex.Provider>
  );
};
