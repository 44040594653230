import React, { useContext, useEffect, useRef, useState } from "react";

import logo from "../../Assets/images/logo.svg";
// import { useNavigate, useLocation } from "react-router-dom";

import cryptoStartup from "../../Assets/images/menulogos/cryptoStartup.svg";
import cryptoMarketing from "../../Assets/images/menulogos/cryptoMarketing.svg";
import cryptoLaw from "../../Assets/images/menulogos/cryptoLaw.svg";
import safeStorage from "../../Assets/images/menulogos/safeStorage.svg";
import blockSoftware from "../../Assets/images/menulogos/blockSoftware.svg";
import cryptoShild from "../../Assets/images/menulogos/cryptoShild.svg";
import news from "../../Assets/images/icons/news.svg";
import insta from "../../Assets/images/menulogos/insta.svg";
import discord from "../../Assets/images/menulogos/discord.svg";
import youtube from "../../Assets/images/menulogos/youtube.svg";
import email from "../../Assets/images/icons/home_logo_3.svg";
import whatsApp from "../../Assets/images/icons/whatsApp.svg";
import connection from "../../Assets/images/icons/connection.svg";
import telegram from "../../Assets/images/icons/telegram.svg";

import homeLogo11 from "../../Assets/images/icons/home_logo_11.svg";
import homeLogo2 from "../../Assets/images/icons/home_logo_2.svg";
import homeLogo3 from "../../Assets/images/icons/home_logo_3.svg";
import homeLogo4 from "../../Assets/images/icons/home_logo_4.svg";

import mobileMenu from "../../Assets/images/icons/mobileMenu.svg";
import mobilemenuClose from "../../Assets/images/icons/mobilemenuClose.svg";

import starfish from "../../Assets/images/menulogos/starfish.svg";
import marketsverse from "../../Assets/images/menulogos/marketsverse.svg";
import viralverse from "../../Assets/images/menulogos/viralverse.svg";
import indianinvestor from "../../Assets/images/menulogos/indianinvestor.svg";
import garage from "../../Assets/images/menulogos/garage.svg";

import searchIcon from "../../Assets/images/icons/search1.svg";

import "./splashHeader.scss";
import SplashSidebar from "../SplashSidebar";
import { GlobalContex } from "../../globalContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SplashHeader = () => {
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  const {
    showDraw,
    setShowDraw,
    selectedMenu,
    setSelectedMenu,
    searchInput,
    setSearchInput,
  } = useContext(GlobalContex);
  const dropdownRef = useRef(null);
  const mobileDrawRef = useRef(null);

  const [allCoins, setAllCoins] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileDraw, setShowMobileDraw] = useState(false);
  const [expandEcoMobile, setExpandEcoMobile] = useState(false);

  const appMenu = [
    {
      icon: cryptoStartup,
      title: "CryptoStartups.com",
      subtitle: "Crypto Business Publication",
      link: "http://cryptostartups.com",
    },
    {
      icon: cryptoMarketing,
      title: "CryptoMarketingPro",
      subtitle: "Blockchain Marketing Agency",
      link: "http://cryptomarketingpro.com",
    },
    {
      icon: cryptoLaw,
      title: "CryptoLaw.com",
      subtitle: "Crypto Legal Publication",
      link: "http://cryptolaw.com",
    },
    {
      icon: safeStorage,
      title: "Safe Storage",
      subtitle: "Institutional Custody Solution",
      link: "http://safe.storage",
    },
    {
      icon: blockSoftware,
      title: "Block.Software",
      subtitle: "Blockchain Development Agency",
      link: "http://block.software",
    },
    {
      icon: cryptoShild,
      title: "CryptocurrencyShield",
      subtitle: "Crypto Insurance Platform",
      link: "http://cryptocurrencyshield.com",
    },
  ];

  const socialMediaMenu = [
    {
      icon: news,
      title: "Blog",
      subtitle: "blog.mycryptobrand.com",
      link: "http://blog.mycryptobrand.com",
    },
    {
      icon: email,
      title: "Email",
      subtitle: "support@inr.group",
      link: "contact@mycryptobrand.com",
    },
    {
      icon: discord,
      title: "Discord",
      subtitle: "Join Server",
      link: "",
    },
    {
      icon: youtube,
      title: "Youtube",
      subtitle: "Go To Channel",
      link: "https://www.youtube.com/channel/UCthJbOwKpKcPSx6gYaMeWTw",
    },
    {
      icon: insta,
      title: "Instagram",
      subtitle: "@inr.group",
      link: "https://www.instagram.com/mycryptobrandofficial/",
    },
    {
      icon: whatsApp,
      title: "WhatsApp",
      subtitle: "@inr.group",
      link: "https://api.whatsapp.com/send?phone=16477234329&text=&source=&data=",
    },
    {
      icon: telegram,
      title: "Telegram",
      subtitle: "@inr.group",
      link: "",
    },
    {
      icon: connection,
      title: "Connection",
      subtitle: "@inr.group",
      link: "",
    },
  ];

  const dropdownMenu = [
    {
      brandName: "Starfish",
      brandLogo: starfish,
      barndLink: "https://starfishgp.com",
    },
    {
      brandName: "Marketsverse",
      brandLogo: marketsverse,
      barndLink: "https://marketsverse.com",
    },
    {
      brandName: "Viralverse",
      brandLogo: viralverse,
      barndLink: "https://viral.group",
    },
    {
      brandName: "IndianInvestor",
      brandLogo: indianinvestor,
      barndLink: "https://indianinvestor.com",
    },
    {
      brandName: "Garage",
      brandLogo: garage,
      barndLink: "https://unlockgarage.com",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".menu-item")
      ) {
        // Click occurred outside the dropdown, hide it
        setShowDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileDrawRef.current &&
        !mobileDrawRef.current.contains(event.target) &&
        !event.target.closest(".menu-item-mobile")
      ) {
        // Click occurred outside the dropdown, hide it
        setShowMobileDraw(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMobileDraw]);

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then((res) => {
        setAllCoins(res.data.coins);
      });
  }, []);

  return (
    <>
      <div>
        {/* <div className="gridTwo">
          <div
            style={{
              color: "white",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              onClick={(e) => window.open("http://globalxchange.com", "_blank")}
              className={!showDraw ? "topIcons" : ""}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <img src={homeLogo11} alt="" />
              </div>
            </div>
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(appMenu);
              }}
              className={!showDraw ? "topIcons" : ""}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img src={homeLogo2} alt="" />
            </div>
          </div>

          <div>&nbsp;</div>
          <div
            style={{
              color: "white",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(socialMediaMenu);
              }}
              // ref={wrapperRef}
              className={!showDraw ? "topIcons1" : ""}
              style={{
                // marginLeft: "-1.5px",
                //   borderLeft: !disableBack ? "0.5px solid #E5E5E5" : "",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                //   pointerEvents: showMenu ? "none" : "",
              }}
            >
              <img
                //   style={{ opacity: !disableBack ? 1 : 0.3 }}
                src={homeLogo3}
                alt=""
              />
            </div>
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(allCoins);
              }}
              className={!showDraw ? "topIcons1" : ""}
              style={{
                //   borderLeft: !disableBack ? "0.5px solid #E5E5E5" : "",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //   opacity: !disableBack ? 1 : 0.4,
              }}
            >
              <img
                //   style={{ opacity: !disableBack ? 1 : 0.4 }}
                src={homeLogo4}
                alt=""
              />
            </div>
          </div>
        </div> */}

        <div className="grid">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "200px",
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <img
                className="menuLogo"
                style={{ width: "100%", cursor: "pointer" }}
                src={logo}
                alt=""
                onClick={(e) => navigate("/")}
              />
            </div>
          </div>
          <div>
            <input
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="searchBox"
              type="text"
              style={{
                padding: "0px 20px",
                //   opacity: !disableBack ? 1 : 0.03,
                border: "none",
              }}
              placeholder="Search.."
            />
          </div>
          {/* <div
          // onClick={(e) => setSelectedNav("About")}
          >
            <span
              className={
                "nonselectednav"
                // pathname === "/" ? "selectednav" : "nonselectednav"
              }
              // onClick={(e) => navigate("/")}
            >
              Tokens
            </span>
          </div>
          <div
          // onClick={(e) => navigate("/templates")}
          >
            <span
              className={
                "nonselectednav"
                // pathname === "/templates" ? "selectednav" : "nonselectednav"
              }
            >
              Dapps
            </span>
          </div>
          <div
          // onClick={(e) => navigate("/templates")}
          >
            <span
              className={
                "nonselectednav"
                // pathname === "/templates" ? "selectednav" : "nonselectednav"
              }
            >
              NFT's
            </span>
          </div>
          <div
          // onClick={(e) => navigate("/templates")}
          >
            <span
              className={
                "selectednav"
                // pathname === "/templates" ? "selectednav" : "nonselectednav"
              }
            >
              Brands
            </span>
          </div>
          <div
          // onClick={(e) => window.open(`http://metaverseapps.io`, "_blank")}
          >
            Games
          </div> */}
          <div
          // onClick={(e) => navigate("/templates")}
          >
            <span
              className={
                "selectednav"
                // pathname === "/templates" ? "selectednav" : "nonselectednav"
              }
            >
              Brands
            </span>
          </div>
          <div
            className="menu-item"
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => setShowDropdown(!showDropdown)}
          >
            <div>Ecosystem&nbsp;</div>
            {showDropdown ? (
              <div
                style={{
                  fontWeight: "bold",
                  marginTop: "4px",
                  // transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                &#8963;
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  transform: "rotate(180deg)",
                  marginTop: "-4px",
                }}
              >
                &#8963;
              </div>
            )}
          </div>
          <div
            style={{
              fontWeight: "700",
              // opacity: !disableBack ? 1 : 0.1,
            }}
          >
            Launch
          </div>
        </div>
        <div className="gridMobile1">
          {showMobileDraw ? (
            <img
              className="menu-item-mobile"
              src={mobilemenuClose}
              alt=""
              onClick={(e) => setShowMobileDraw(!showMobileDraw)}
            />
          ) : (
            <img
              src={mobileMenu}
              alt=""
              onClick={(e) => setShowMobileDraw(!showMobileDraw)}
            />
          )}
          <img
            style={{ cursor: "pointer" }}
            src={logo}
            alt=""
            onClick={(e) => navigate("/")}
          />
          <img
            className="menu-item-mobile"
            src={searchIcon}
            alt=""
            // onClick={(e) => setShowMobileDraw(!showMobileDraw)}
          />
        </div>
      </div>
      {showDraw ? (
        <div
          style={{
            // display: "static",
            height: "100vh",
            width: "350px",
            background: "white",
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: 0,
          }}
        >
          <SplashSidebar
            showDraw={showDraw}
            setShowDraw={setShowDraw}
            selectedMenu={selectedMenu}
          />
        </div>
      ) : (
        ""
      )}
      {showDropdown && (
        <div ref={dropdownRef} className="dropDown">
          {dropdownMenu.map((item) => (
            <div onClick={(e) => window.open(`${item.barndLink}`, "_blank")}>
              <img src={item?.brandLogo} />
              <span style={{ paddingLeft: "10px" }}>{item?.brandName}</span>
            </div>
          ))}
        </div>
      )}
      {showMobileDraw && (
        <div ref={mobileDrawRef} className="mobileDraw">
          <div>
            <div className="mobileBrand">Brands</div>
            <div
              className="mobileEco"
              onClick={(e) => setExpandEcoMobile(!expandEcoMobile)}
            >
              <div>Ecosystem</div>
              {expandEcoMobile ? (
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "4px",
                    // transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  &#8963;
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    transform: "rotate(180deg)",
                    marginTop: "-4px",
                  }}
                >
                  &#8963;
                </div>
              )}
            </div>
            {expandEcoMobile &&
              dropdownMenu.map((item) => (
                <div
                  className="expandEcoMenu"
                  onClick={(e) => window.open(`${item.barndLink}`, "_blank")}
                >
                  {item.brandName}
                </div>
              ))}
          </div>
          <div className="mobileLaunchBtn">Launch</div>
        </div>
      )}
    </>
  );
};

export default SplashHeader;
